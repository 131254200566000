import { combineReducers } from "redux";
import companyList from "./companyList";
import todoList from "./todoList";
import documentList from "./documentList";
import actionsLogList from "./actionsLogList";
import list from "./list";
import create from "./create";
import update from "./update";
import del from "./delete";
import show from "./show";

export default combineReducers({
  companyList,
  todoList,
  documentList,
  actionsLogList,
  list,
  create,
  update,
  del,
  show
});
