import { combineReducers } from "redux";

export function error(state = null, action) {
  switch (action.type) {
    case "PROFILE_ERROR":
      return action.error;

    default:
      return state;
  }
}

export function loading(state = false, action) {
  switch (action.type) {
    case "PROFILE_LOADING":
      return action.loading;

    default:
      return state;
  }
}

export function profile(state = null, action) {
  switch (action.type) {
    case "PROFILE_SUCCESS":
      return action.profile;

    default:
      return state;
  }
}

export default combineReducers({ error, loading, profile });
