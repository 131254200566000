import { fetch } from "../../utils/dataAccess";
import { setCookie, deleteCookie } from "../../utils/cookies";

export function error(error) {
  return { type: "LOGIN_ERROR", error };
}

export function loading(loading) {
  return { type: "LOGIN_LOADING", loading };
}

export function success(logged) {
  if (logged) setCookie("token", logged.token, 1);
  else deleteCookie("token");
  return { type: "LOGIN_SUCCESS", logged };
}

export function login(user) {
  return dispatch => {
    dispatch(error(null));
    dispatch(success(null));
    dispatch(loading(true));

    return fetch("login", {
      method: "POST",
      body: JSON.stringify(user)
    })
      .then(response => response.json())
      .then(credentials => {
        dispatch(loading(false));
        dispatch(success(credentials));
      })
      .catch(e => {
        dispatch(loading(false));
        dispatch(error(e.message));
      });
  };
}

export function logout() {
  return success(null);
}

export function checkLoading(loading) {
  return { type: "CHECK_LOADING", loading };
}

export function checkError(error) {
  return { type: "CHECK_ERROR", error };
}

export function check(token) {
  return dispatch => {
    dispatch(checkLoading(true));
    // use 'profile' or 'check' endpoint instead when it's ready
    return fetch("dealers")
      .then(resp => {
        console.log("success", resp);
        dispatch(checkLoading(false));
        dispatch(success({ token }));
      })
      .catch(e => {
        dispatch(checkLoading(false));
        dispatch(checkError(e.message));
      });
  };
}
