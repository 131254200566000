import { combineReducers } from "redux";
import list from "./list";
import actionsLogList from "./actionsLogList";
import create from "./create";
import update from "./update";
import del from "./delete";
import show from "./show";

export default combineReducers({
  list,
  actionsLogList,
  create,
  update,
  del,
  show
});
