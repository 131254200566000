import { ENTRYPOINT } from "../config/entrypoint";
import { SubmissionError } from "redux-form";
import get from "lodash/get";
import has from "lodash/has";
import isArray from "lodash/isArray";
import isObject from "lodash/isObject";
import mapValues from "lodash/mapValues";
import { checkCookie, deleteCookie } from "./cookies";

const MIME_TYPE = "application/ld+json";

export function fetch(id, options = {}) {
  if ("undefined" === typeof options.headers) options.headers = new Headers();
  if (null === options.headers.get("Accept"))
    options.headers.set("Accept", MIME_TYPE);

  if (null === options.headers.get("Authorization")) {
    const token = checkCookie();
    if (token && token !== "undefined")
      options.headers.set("Authorization", `Bearer ${token}`);
  }

  if (
    "undefined" !== options.body &&
    !(options.body instanceof FormData) &&
    null === options.headers.get("Content-Type")
  )
    options.headers.set("Content-Type", MIME_TYPE);

  return global.fetch(new URL(id, ENTRYPOINT), options).then(response => {
    if (response.ok) return response;

    if (response.status === 401) {
      deleteCookie("token");
      window.location.reload(true);
    }

    return response.json().then(json => {
      const error = json["hydra:description"] || response.statusText;
      if (!json.violations) throw Error(error);

      let errors = { _error: error };
      json.violations.map(
        violation => (errors[violation.propertyPath] = violation.message)
      );

      throw new SubmissionError(errors);
    });
  });
}

export function mercureSubscribe(url, topics) {
  topics.forEach(topic =>
    url.searchParams.append("topic", new URL(topic, ENTRYPOINT))
  );

  return new EventSource(url.toString());
}

export function normalize(data) {
  if (has(data, "hydra:member")) {
    // Normalize items in collections
    data["hydra:member"] = data["hydra:member"].map(item => normalize(item));

    return data;
  }

  // Flatten nested documents
  return mapValues(data, value => {
    switch (true) {
      case isArray(value):
        return value.map(v => get(v, "@id", v));
      case isObject(value):
        return value["@id"];
      default:
        return value;
    }
  });
}

export function extractHubURL(response) {
  const linkHeader = response.headers.get("Link");
  if (!linkHeader) return null;

  const matches = linkHeader.match(
    /<([^>]+)>;\s+rel=(?:mercure|"[^"]*mercure[^"]*")/
  );

  return matches && matches[1] ? new URL(matches[1], ENTRYPOINT) : null;
}

export function getValidTime(datetime) {
  if (/.*T[\d]{2}:[\d]{2}$/g.test(datetime)) {
    // if e.g. `2020-06-24T12:00`
    return `${datetime}+00:00`;
  } else {
    // if e.g. 2020-06-24T12:00:00+00:00
    return datetime;
  }
}
