import { combineReducers } from "redux";

import themeReducer from "../store/reducer";
import { reducer as form } from "redux-form";

import dealer from "./dealer/";
import user from "./user/";
import make from "./make/";
import country from "./country/";
import language from "./language/";
import searchRequest from "./search-request/";
import todo from "./todo/";
import document from "./document/";
import alias from "./alias/";
import auth from "./auth/";
import contact from "./contact/";
import communication_method from "./communication_method/";
import communication from "./communication/";
import dealer_assessment from "./dealer_assessment/";
import actions_log from "./actions_log/";
import company from "./company/";
import email from "./email/";
import report from "./report/";
import report_status from "./report_status/";

export default combineReducers({
  /* theme reducers */
  themeReducer,
  form,

  /* project reducers */
  dealer,
  user,
  make,
  country,
  language,
  searchRequest,
  todo,
  document,
  alias,
  auth,
  contact,
  communication_method,
  communication,
  dealer_assessment,
  actions_log,
  company,
  email,
  report,
  report_status
});
