import { combineReducers } from "redux";

export function error(state = null, action) {
  switch (action.type) {
    case "REPORT_LIST_ERROR":
      return action.error;

    case "REPORT_LIST_MERCURE_DELETED":
      return `${action.retrieved["@id"]} has been deleted by another user.`;

    case "REPORT_LIST_RESET":
      return null;

    default:
      return state;
  }
}

export function loading(state = false, action) {
  switch (action.type) {
    case "REPORT_LIST_LOADING":
      return action.loading;

    case "REPORT_LIST_RESET":
      return false;

    default:
      return state;
  }
}

export function retrieved(state = null, action) {
  switch (action.type) {
    case "REPORT_LIST_SUCCESS":
      return action.retrieved;

    case "REPORT_LIST_RESET":
      return null;

    case "REPORT_LIST_MERCURE_MESSAGE":
      return {
        ...state,
        "hydra:member": state["hydra:member"].map(item =>
          item["@id"] === action.retrieved["@id"] ? action.retrieved : item
        )
      };

    case "REPORT_LIST_MERCURE_DELETED":
      return {
        ...state,
        "hydra:member": state["hydra:member"].filter(
          item => item["@id"] !== action.retrieved["@id"]
        )
      };

    default:
      return state;
  }
}

export function eventSource(state = null, action) {
  switch (action.type) {
    case "REPORT_LIST_MERCURE_OPEN":
      return action.eventSource;

    case "REPORT_LIST_RESET":
      return null;

    default:
      return state;
  }
}

export function sorting(state = getDefaultSorting(), action) {
  switch (action.type) {
    case "SET_LIST_SORTING":
      return action.sorting;

    default:
      return state;
  }
}

export function filters(state = getDefaultFilters(), action) {
  switch (action.type) {
    case "ADD_LIST_FILTER":
      return { ...state, [action.filter.name]: action.filter.value };

    case "LIST_FILTER_RESET":
      return {};

    default:
      return state;
  }
}

function getDefaultSorting() {
  const params = new URLSearchParams(
    decodeURIComponent(window.location.href).split("?")[1]
  );

  return (
    Array.from(params.entries()).filter(
      item => item[0].indexOf("order[") !== -1
    )[0] || {}
  );
}

function getDefaultFilters() {
  const params = new URLSearchParams(
    decodeURIComponent(window.location.href).split("?")[1]
  );

  const items = Array.from(params.entries())
    .filter(item => item[0].indexOf("order[") === -1 && item[0] !== "page")
    .reduce((acc, item) => {
      return { ...acc, [item[0]]: item[1] };
    }, {});

  return items;
}

export default combineReducers({
  error,
  loading,
  retrieved,
  eventSource,
  filters,
  sorting
});
