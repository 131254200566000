import thunk from "redux-thunk";
import { applyMiddleware, compose } from "redux";

// const urlSync = store => next => action => {
//   if (action.type === "ADD_LIST_FILTER") {
//     const params = new URLSearchParams();
//     store.getState().filters.forEach(filter => params.append("filter", filter));
//     params.append("item", action.data);
//     window.location.hash = `?${params.toString()}`;
//   }
//   next(action);
// };

const composables = [
  applyMiddleware(thunk),
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
].filter(Boolean);

const middleware = compose(...composables);

export default middleware;
