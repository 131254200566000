import { combineReducers } from "redux";
import list from "./list";
import todoList from "./todoList";
import reportList from "./reportList";
import actionsLogList from "./actionsLogList";
import create from "./create";
import update from "./update";
import del from "./delete";
import show from "./show";

export default combineReducers({
  list,
  create,
  update,
  del,
  show,
  todoList,
  reportList,
  actionsLogList
});
